import { FaRupeeSign, FaClock } from "react-icons/fa"
import { Link } from "react-router-dom"



const PackItem = ( { data } ) =>{
    
    return(
        <div className="packDestination">
            <div className='imageInfo'>
                <img src={data.imgSrc} alt={data.destTitle} />
            </div>

            <div className='packCardInfo'>
                <h4 className='packDestTitle'>{data.destTitle}</h4>
                <span className='packContent flex'>
                    <FaClock className="icon"/>
                    <small>{`${data.duration} days ${parseInt(data.duration)-1} nights`}</small>
                    
                </span>
                <span className='name'>{data.location}</span>

                <div className='fees flex'>
                    <div className='grade'>
                        <span>{data.grade} <small>{`Customize`}</small></span>
                    </div>
                    <div className='price flex'>
                    <h5><FaRupeeSign className="icon" />{data.price}</h5>
                        <span>{`Per Person`}</span>
                    </div>
                </div>

                <div className='desc'>
                    {/* <p>{data.description}</p> */}
                    <ul>
                        {
                            data.overview.map(
                                (item)=>
                                (<li key={item.id}>
                                    {item.description}
                                </li>)
                            )
                        }
                    </ul>
                </div>

                <Link to={`/packages/details/${data.packId}`}>
                    <button className='btn flex' >Details</button>
                </Link>
            </div>
        </div>

        
    )
}


export default PackItem