import './main.css'
import PackageItem from '../Pack/PackItem'
import { useEffect, useState } from 'react'
import useData from '../../hooks/useData'

const Special = () => {

    const { packages } = useData()

    const [special, setSpecial] = useState([])

    const compare = ( a, b ) => {
        if ( parseInt(a.priority) > parseInt(b.priority)){
            return 1;
        }
        if ( parseInt(a.priority) < parseInt(b.priority)){
            return -1;
        }
        else{
            return 0;
        }
    }

    

    useEffect(()=>{
        const specialPack = packages.sort(compare) 
        let count = 4 
        const special = specialPack.filter((pack) =>  {return --count >= 0} )
        setSpecial(special)
    },[packages])

    
    return(
        <section className="main container section">
            <div className="mainTitle">
                <h3 className="mainMainTitle">
                    Winter Special Packages
                </h3>
            </div>
            

            <div className="mainSectionContent grid">
                {
                    special.map((data) => 
                        <PackageItem
                            key={data.packId}
                            data={data}
                        />
                    )
                }
            </div>

        </section>
    )
}





export default Special