import "./whyus.css"

const WhyUs = () => {
  

  return (
    <div className="WhyUs container section">
        <div className="WhyUsTitle">
            <h2>Why to choose us?</h2>
        </div>

        <div className="headSection">
            At Explorers United, we are committed to making your travel experience seamless, enjoyable, and tailored to your needs. Here’s why travelers trust us for unforgettable journeys:


            <ul>
                <li>
                    <div className="headText">Customizable Itineraries</div>
                    <div className="desc">Your travel, your way! We offer fully customizable itineraries that allow you to design the perfect trip according to your preferences. Whether it’s adventure, leisure, or a cultural exploration, we ensure every detail fits your unique travel style.</div>
                </li>

                <li>
                    <div className="headText">Low Booking Deposit – Just 10%</div>
                    <div className="desc">Planning a vacation shouldn’t be a financial strain. With us, you can secure your dream holiday with just a 10% deposit of the total package cost, making travel planning easy and accessible.</div>
                </li>

                <li>
                    <div className="headText">Economic Travel Rates in Kashmir</div>
                    <div className="desc">Explore the breathtaking beauty of Kashmir without breaking the bank. We offer competitive and budget-friendly packages, so you can experience the magic of this stunning region at the best rates available.</div>
                </li>

                <li>
                    <div className="headText">On-the-Spot Sightseeing</div>
                    <div className="desc">We understand that spontaneous moments can create the best memories. With our flexible tour services, you can enjoy on-the-spot sightseeing at your own pace, ensuring you don’t miss out on any hidden gems along the way.</div>
                </li>
            </ul>
        </div>
      
    </div>
  );
};

export default WhyUs;