import './queries.css'
// import { FaQuestion } from "react-icons/fa"
import { RiQuestionnaireFill } from "react-icons/ri";
import { Link } from 'react-router-dom'


const Queries = () => {
    const scrollToContactForm = () => {
        
        const heroElement = document.getElementById("contactForm");
        
        window.scrollTo({
            top: heroElement,
            behavior: 'smooth'
        })
    }

    return (
        <Link to="/"><button className="queries" onClick={scrollToContactForm}>
            <RiQuestionnaireFill className="queriesIcon" />
        </button></Link>
        
    )
}

export default Queries