import './home.css'
import Main from './Main'
import WhyUs from '../WhyUs/WhyUs'
import Popular from "../Popular/Popular"
import Special from "../Special/Special"
import Hot from "../Hot/Hot"
import FAQ from "../FAQ/Faq"
import Card from './Card'
// import Poster from '../Poster/Poster'

const Home = () => {
    return(
        <>
            <Main/>
            <WhyUs/>
            <Special/>
            {/* <Poster/> */}
            <Popular/>
            <Hot/>
            <FAQ/>
            <div className="mainCard section container">
                <h2>Query Form</h2>
                <Card/>
            </div>
        </>
    )
}

export default Home