// src/components/FAQ.js
import "./faq.css"
import React, { useState } from 'react';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What meals are included in the package?",
      answer: "We provide breakfast and dinner, which are included in the package.",
    },
    {
      question: 'Do we also provide trekking facilities?',
      answer: 'Yes we make necessary arrangements for the trekking and adventure sport (charges may subject to the sport).',
    },
    {
      question: 'Is there an additional charge for transportation?',
      answer: 'No, we do not charge any additional amount for transport (including pickup and drop facilities), it is included in the package.',
    },
    {
      question: "Can the itinerary be customized to meet the customer's preferences?",
      answer: "Yes the itinerary can be customized as per the customer's prefrence",
    },
    {
      question: 'Is there any additional facility that can be requested?',
      answer: 'Charges may be subject to the requested facility.',
    },
    {
      question: 'What amount need to be paid for the booking?',
      answer: "We charge only 10% of the total amount in advance, and the remaining balance is collected during the customer's journey.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq container section">
        <div className="faqTitle">
            <h2>Frequently Asked Questions</h2>
        </div>
      {
        faqs.map((faq, index) => (
            
            <div key={index} className={`faqItem ${activeIndex === index ? 'active' : ''}`}>
                <div className="faqQuestion"
                    onClick={() => toggleFAQ(index)}>
                    {faq.question}
                </div>
                {
                    activeIndex === index && (
                        <div className="faqAnswer">
                            {faq.answer}
                        </div>
                    )
                }
            </div>
         ))
    }
    </div>
  );
};

export default FAQ;
